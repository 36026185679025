#progressbar {
    overflow: hidden;
    color: black;
}

#progressbar .active {
    color: #0073BB;
}

#progressbar .semi-active {
    color: #0073BB;
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 23%;
    float: left;
    position: sticky;
    font-weight: 400;
}

/*Icons in the ProgressBar*/

#progressbar #ingestion:before {
    font-family: FontAwesome;
    content: "";
}

#progressbar #review:before {
    font-family: FontAwesome;
    content: "";
}

#progressbar #approve:before {
    font-family: FontAwesome;
    content: "";
}

#progressbar #filing_in_progress:before {
    font-family: FontAwesome;
    content: "";
}

#progressbar #filed:before {
    font-family: FontAwesome;
    content: "";
}

/*Icon ProgressBar before any progress*/

#progressbar li:before {
    width: 10px;
    height: 10px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 0 10px 0;
    padding: 2px;
}

/*ProgressBar connectors*/

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 5px;
    z-index: -1;
}

/*Color number of the step and the connector before it*/

#progressbar li.active:before, #progressbar li.active:after {
    background: #0073BB;
}

#progressbar li.semi-active:before {
    background: #0073BB;
}

/*Animated Progress Bar*/

.progress {
    height: 20px;
}

.progress-bar {
    background-color: #0073BB;
}

/*Fit image in bootstrap div*/

.fit-image{
    width: 100%;
    object-fit: cover;
}

#progressbar .clickable {
    cursor: pointer;
    text-decoration: none;
}

#template-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}