.quicksightLink {
    margin-left: 0.5%;  
}

.dashboard {
    margin-top: 10px;
    width: 100%;
    height: 750px;
}

.transaction-header-actions {
    align-items: end;
}