/* TODO: Change the px to ems */
.excalibur {
    margin-left: 5%;
    margin-right: 5%;
}

.hidden {
    opacity: 0;
}

.inputButton {

    width: 100%;
    height: 100%;
    margin-right: 30%;
    margin-bottom: 5%;
    position: absolute;
}

#buttonGroup > * {
    margin-right: 2.5%;
    margin-bottom: 0.5%;
    margin-top: 2%;
}

#optionsGroup > * {
    margin-top: 2%;
}

.header {
    margin-bottom: 0.5%;
    margin-top: 0.5%;
}

.uploadSpinner {
    margin-top: 2.5%!important;
}

.efilebtn {
    width: 130px;
}
