.home {
    margin-left: 5%;
    margin-right: 5%;
}

.grid-elem {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
}

.launchBtn {
   position: absolute !important;
   bottom: 1px;
}

.supportBtn {
    padding-left: 0% !important;
}

.Home-subheader {
    color: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
}

.Home-header {
    color: #ffffff;
    padding-left: 5%;
    padding-right: 40%;
    padding-top: 8px;
    padding-bottom: 40px;
}