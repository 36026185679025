/* TODO: Change the px to ems */
.phastos {
    margin-left: 5%;
    margin-right: 5%;
}

.phastos-exception {
    margin-left: 2.25vw;
    margin-right: 1vw;
}

.float-box {
    padding: 0 0 20px 0;
}

.float {
    display: inline-block;
    border: 1px solid #c7c9c9;
    border-right: 0;
    text-align: center;
    padding: 0 5px 0 5px;
    min-width: 100px;
}

.margin {
    margin-left: 1%
}

.lastElem {
    border-right: 1px solid #c7c9c9;
}

.action-box {
    display: flex;
}

.download-box {
    margin-left: 4%;
}
.clickable {
    color: #551A8B;
    text-decoration: underline;
    cursor: pointer;
}