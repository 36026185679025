.boxLabel {
    margin: 5px;
    padding: 5px 1px;
    width: 136px;
}

.operation {
    margin: 5px;
    padding: 5px 1px;
    width: 168px;
}

.boxValue {
    margin: 5px;
    padding: 5px 1px;
    width: 136px;
}

.adjustDescription {
    margin: 5px;
    padding: 5px 1px;
    width: 136px;
}

.add-button {
    margin-top: 8px;
    border: none;
    background: white;
    font-size: 15px;
    cursor: pointer;
}

.remove-button {
    margin-top: 12px;
    border: none;
    cursor: pointer;
    background-color: white;
    background-image: url('../../../../../../src/common/icons/cross.svg') !important;
    height: 24px !important;
    width: 24px !important;
}

.modalComponent {
    display: flex;
    flex-direction: row;
}

.adjustmentHeader {
    display: flex;
    align-items: center;
}

.errorTag {
    margin: 5px;
    padding: 5px 1px;
    color: #d13212;
    width: 136px;
}

.add-remove-button{
    margin-top: 10px;
    align-self: flex-start;
}

.add-cross-button{
    margin-top: 10px;
    align-self: flex-start;
}

.intrastatSummaryReport{
    height: 600px;
    overflow: auto;
}
