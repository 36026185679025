#buttonGroup {
    display: flex;
    flex-direction: row;
}

#buttonGroup > * {
    margin-right: 2.5%;
    margin-bottom: 0.5%;
}

#buttonGroup2 {
    display: flex;
    flex-direction: row;
}

#buttonGroup2 > * {
    margin-right: 2.5%;
    margin-bottom: 0.5%;
}

.download-btn {
    height: 32px;
}

.header {
    margin-bottom: 0.5%;
    margin-top: 0.5%;
    color:aquamarine
}

.table {
    margin-bottom: 2%;
}

.upload-button {
    width: 100%;
    float: right;
}

.inputButton {
    width: 100%;
    height: 100%;
    margin-right: 30%;
    margin-bottom: 5%;
    position: absolute;
}