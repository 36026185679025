.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

.BreadCrumbGroupDiv {
  padding-left: 5%;
  padding-top: 72px;
  background-color: #f2f3f3;
}

.PageLoadingSpinner {
  position: fixed;
  top: 50%;
  left: 48%;
  text-align: center;
  display: flex;
  align-items: center;
}

.ComponentSpinner {
  position: center;
  top: 50%;
  left: 48%;
  text-align: center;
  display: flex;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Hone page-related CSS */

.column-layout-key {
  margin-top: 0px;
}

.column-layout-value {
  margin-bottom: 0px;
}

/* Correspondence-related CSS */

.awsui_drawer-content_lm6vo_xtmft_158:not(#\9) {
  top: 56px !important;
  box-shadow: var(--shadow-panel-v9vx7i, 0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)) !important;
}

.awsui_tools-toggle_lm6vo_xtmft_251.awsui_tools-toggle_1fj9k_avnjw_41.awsui_button_vjswe_17xsg_101.awsui_variant-icon_vjswe_17xsg_162.awsui_button-no-text_vjswe_17xsg_881>.awsui_icon_vjswe_17xsg_901.awsui_icon-left_vjswe_17xsg_901.awsui_icon_h11ix_31bp4_98.awsui_size-normal-mapped-height_h11ix_31bp4_151.awsui_size-normal_h11ix_31bp4_147.awsui_variant-normal_h11ix_31bp4_219 {
  display: none !important;
}

.awsui_tools-toggle_lm6vo_xtmft_251.awsui_tools-toggle_1fj9k_avnjw_41.awsui_button_vjswe_17xsg_101.awsui_variant-icon_vjswe_17xsg_162.awsui_button-no-text_vjswe_17xsg_881 {
  height: 24px !important;
  width: 24px !important;
  top: 4px !important;
  background-image: url('chat.svg') !important;
}

.awsui_drawer_lm6vo_xtmft_138 {
  box-shadow: none !important;
}