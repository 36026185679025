.box {
 display: flex;   
}

.box-p {
 margin-top: auto;
 padding-left: 2px;
}

.header {
    display: flex;
}

.dropdown {
    margin-top: 1%;
    margin-left: 5%;
}

.actions-box {
    float: right
}

.container {
  display: flex;
}

.summary-box {
    width: 250px;
    height: 100px;
}

.filter {
    display: flex;
}

.filter-top-item {
    width: 40%;
}

.filter-item {
    margin-left: 2.5%;
    width: 25%;
}

.complete-button {
    float: right;
}