.filing {
    margin-left: 5%;
    margin-right: 5%;
}

.transactionDetails {
    margin-left: 5%;
    margin-right: 5%;
}

.hidden {
    opacity: 0;
}

.inputButton {
    width: 100%;
    height: 100%;
    margin-right: 30%;
    margin-bottom: 5%;
    position: absolute;
}
#buttonGroup {
    display: flex;
    flex-direction: row;
}

#buttonGroup > * {
    margin-right: 2.5%;
    margin-bottom: 0.5%;
}

.download-btn {
   height: 32px; 
}

.uploadBox {
    margin-top: 1%;
    display: flex;
}

.uploadBox > p {
    margin-top: 0;
    margin-bottom: 2;
}

.file-d {
    margin-top: 0;
    margin-bottom: 0;
    color: #687078
}

.header {
    margin-bottom: 0.5%;
    margin-top: 0.5%;
    color:aquamarine
}

.uploadSpinner {
    margin-top: 2.5%!important;
}

.successmsg {
    margin-left: 1%;
}

.authBox {
    display:flex;
    flex-direction: row;
}

.auth-btn {
    margin-left: 10px;
}

.uploadBox > * {
    margin-right: 2.5%;
}

.submitBox {
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: right;
}

.table {
    margin-bottom: 20%;
}

.reauthenticate-btn {
    margin-left: 10px;
}

#excelPreviewTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
#excelPreviewTable td, #excelPreviewTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

#excelPreviewTable tr:nth-child(even){background-color: white;}

#excelPreviewTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2;
    color: black;
}
